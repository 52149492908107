section#comments {
  .comment {
    display: grid;
    column-gap: 1rem;
    grid-template-areas:
    "avatar name"
    "avatar time"
    "avatar post"
    "...... card"
    "...... interactions";
    grid-template-columns: min-content;
    justify-items: start;
    margin: 2em auto 2em -1em;
    padding: 1em;

    .avatar-link {
      grid-area: avatar;
      height: 4rem;
      position: relative;
      width: 4rem;

      .avatar {
        height: 100%;
        width: 100%;
      }

      &.op::after {
        background-color: var(--secondary-accent-color);
        border-radius: 50%;
        bottom: -0.25rem;
        color: var(--secondary-accent-contrast);
        content: "✓";
        display: block;
        font-size: 1.25rem;
        font-weight: bold;
        height: 1.5rem;
        line-height: 1.5rem;
        position: absolute;
        right: -0.25rem;
        text-align: center;
        width: 1.5rem;
      }
    }

    .author {
      align-items: center;
      cursor: default;
      display: flex;
      font-weight: bold;
      gap: 0.5em;
      grid-area: name;

      .instance {
        background-color: var(--faint);
        border-radius: 9999px;
        color: var(--neutral);
        font-size: smaller;
        font-weight: normal;
        padding: 0.25em 0.75em;

        &:hover {
          opacity: 0.8;
          text-decoration: none;
        }

        &.op {
          background-color: var(--secondary-accent-color);
          color: var(--secondary-accent-contrast);

          &::before {
            content: "✓";
            font-weight: bold;
            margin-inline-end: 0.25em;
            margin-inline-start: -0.25em;
          }
        }
      }
    }

    time {
       @extend small !optional;

      grid-area: time;
      line-height: 1.5rem;

      &.edited::after {
        content: " *";
      }
    }

    main {
      grid-area: post;
      justify-self: stretch;

      p:first-child {
        margin-top: 0.25em;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    .card {
      color: inherit;
      grid-area: card;
      max-width: 400px;

      &:hover {
        text-decoration: none;
      }

      figure {
        border-radius: inherit;
        overflow: hidden;
      }

      figcaption {
        display: grid;
        gap: 0.5em;
        margin: 0;
        padding: 1em;
        text-align: left;

        * {
          display: -webkit-box;
          line-height: 1.25;
          margin: 0;
          overflow: hidden;
          padding: 0;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }

    // Hide the card from the shared post
    &:first-of-type .card {
      display: none;
    }

    footer {
       @extend small !optional;

      display: flex;
      gap: 1.25em;
      grid-area: interactions;
      margin-top: 0.925rem;

      .faves {
        cursor: default;

        &::before {
          color: var(--accent-color);
          content: "♥";
          margin-inline-end: 0.25em;
        }
      }

      .boosts {
        cursor: default;

        &::before {
          color: var(--secondary-accent-color);
          content: "🔁";
          margin-inline-end: 0.25em;
        }
      }
    }

    .emoji {
      display: inline;
      height: 1.25em;
      vertical-align: middle;
      width: 1.25em;
    }

    .invisible {
      display: none;
    }

    .ellipsis::after {
      content: "…";
    }

    details {
      summary {
        background-image:
        linear-gradient(
          90deg,
          transparent,
          transparent 0.4rem,
          var(--faint) 0.4rem,
                        var(--faint) calc(100% - 0.4rem),
                        transparent calc(100% - 0.4rem),
                        transparent
        ),
        repeating-linear-gradient(
          45deg,
          var(--faint),
                                  var(--faint) 0.3rem,
                                  var(--accent-color) 0.3rem,
                                  var(--accent-color) 0.6rem
        );
        border-radius: 0.25rem;
        color: inherit;
        cursor: default;
        margin-top: 0.925rem;
        padding: 1em;

        &::after {
          content: "▸";
          display: inline-block;
          margin-left: 0.5em;
          transition: transform 250ms ease;
        }
      }

      &[open] summary::after {
        transform: rotate(90deg);
      }
    }
  }
}
