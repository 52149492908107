:root {
  --bs-primary: rgb(233,84,32) !important;
  --bs-primary-rgb:233,84,32 !important;
}

.content-area {
  padding: 30px;
  border-radius: 10px;
  max-width: 800px;
  margin: 0 auto;
}

@media (prefers-color-scheme: light) {
  .content-area {
  background-color: #f5f5f5;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --bs-body-bg: rgb(24,26,27) !important;
  }
  .content-area {
  background-color: rgb(30,32,33);
  }
}
