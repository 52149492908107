h1, h2, h3, h4, h5 {
    font-family: 'Noto Naskh Arabic', serif;
}

a {
    text-decoration: none !important;
}

/**
 * Images
 */
img {
    max-width: 100%;
    vertical-align: middle;
}

/**
 * Blockquotes
 */
blockquote {
    color: $grey-color;
    border-right: 4px solid $grey-color-light;
    padding-right: $spacing-unit / 2;
    font-size: $base-font-size;
    letter-spacing: -1px;
    font-style: italic;

    > :last-child {
        margin-bottom: 0;
    }
}

/**
 * Code formatting
 */
pre,
code {
    direction: ltr;
    font-family: Ubuntu Mono;
    font-size: $base-font-size;
    border: 1px solid $grey-color-light;
    border-radius: 3px;
    background-color: #eef;
    text-align: left;
}

code {
    padding: 1px 5px;
}

pre {
    padding: 8px 12px;
    overflow-x: scroll;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}

@media (prefers-color-scheme: dark) {
  :root {
    --bs-primary-rgb: 172,6,65;
    --bs-primary: rgb(172, 6, 65);
    --bs-body-bg: rgb(30, 32, 33);
  }
}
